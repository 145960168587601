const works = [
  {
    id: "rapid",
    title: "Rapid Response",
    img: "/assets/rapid.svg",
    text: [
      "Mnemonic’s rapid response project is designed to quickly coordinate and equip existing documentation initiatives working in locations where human rights violations must be documented but the ecosystems to do so are underdeveloped.",
      "We help those wanting to rapidly establish the infrastructures to archive content as well as those who need help reinstating valuable information removed by social media companies. Currently, our rapid response project is preserving content from Iran, Chile, Myanmar and Ethiopia.",
    ],
    callToAction: {
      text: "If you know of valuable content that needs preserving or needs to be reinstated",
      button: "Get in touch",
      href: "contact-us",
    },
  },
  {
    id: "training",
    title: "Training",
    img: "/assets/training.svg",
    text: [
      "Thousands of people from around the world risk their lives to capture videos and images exposing human rights violations.",
      "To effectively make use of this valuable content to support advocacy research and legal case building, robust standards for archiving and investigating should be implemented and expertise must be shared beyond a select few gatekeepers within the open-source investigative community. More individuals and organisations from historically excluded communities need to be equipped and empowered to use archival and open-source investigative tools and techniques. This will allow affected groups to create and own the narrative of incidents they are seeking justice for.",
      "Realising these problems, Mnemonic has trained over one thousand human rights defenders and journalists to use digital information to advance social justice, expanding the use of best practices within civil society. We organise training on tools and archival strategies for analysing and verifying digital information.",
    ],
    callToAction: {
      text: "If this training could be relevant get in touch to find out more",
      button: "Get in touch",
      href: "contact-us",
    },
  },
  {
    id: "content-moderation",
    title: "Content Moderation",
    img: "/assets/research.svg",
    text: [
      "Platforms have become accidental, but unstable archives for millions of important videos and posts. Material that helps preserve memory, document human rights violations, and aid truth and reconciliation efforts in conflict areas is lost every day -- we are working to find it. Sometimes this content violates platforms’ terms and conditions, but often it doesn’t, and even content deemed inappropriate by a platform can have extreme value to the human rights community.",
      "Since 2017, we have engaged in research, monitoring, advocacy and media work surrounding the takedowns of human rights documentation on social media platforms. Alongside this we are developing workflows and methodologies to effectively use this documentation for human rights work at scale.",
      "Content moderation has been thrust into the spotlight in recent years, but often conversations about takedowns suffer from a lack of data and a lack of understanding of the real-world impacts of the decision technology companies make. We lead the field in research on content moderation. Our tracking of takedowns is unparalleled. We raise the issue’s profile by engaging social media platforms, lawmakers, and human rights organisations in our policy work and empower advocacy efforts by providing comprehensive reliable data.",
    ],
    callToAction: {
      text: "Read more about the impact of our work here",
      button: "Read more",
      href: "about",
    },
  },
  {
    id: "tools-and-method",
    title: "Tools and Methods",
    img: "/assets/tools.svg",
    text: [
      "There is a shortage of good available tools and methodologies with which human rights defenders can discover, archive, annotate, and triage digital content on a large scale. Mnemonic builds and supports the development of tools to increase human right’s defenders capacity to use digital information to advance social justice.",
      "To achieve transparency, software, workflows and methods developed by Mnemonic are released in free and open-source formats, allowing for increased flexibility, scalability, and customisation. In our view, technical solutions need to include software whose code is transparent, inspectable, changeable and free of charge. Where possible Mnemonic integrates existing open-source tools to ensure that work is not duplicated. Transparency ensures trust is built and maintained with our partners and collaborators, as well as allowing software to be reused and customised by other groups."
    ],
    callToAction: {
      text: "Read more about how we do our work",
      button: "read more",
      href: "about/methods",
    },
  },
  {
    id: "archive",
    title: "Archive",
    img: "/assets/archive.svg",
    text: [
      "We specialise in developing accessible and long term preservation strategies and open source tools to archive at-risk digital information valuable to journalists and human rights defenders. Mnemonic’s four standalone archives –  <a href='https://syrianarchive.org/'>Syrian Archive</a>, <a href='https://yemeniarchive.org/'>Yemeni Archive</a>, <a href='https://sudanesearchive.org/'>Sudanese Archive</a>, and <a href='https://ukrainianarchive.org/'>Ukrainian Archive</a> have preserved over ten million records.",
      "Through collecting, verifying, preserving, and investigating digital information documenting human rights violations Mnemonic helps preserve data as a digital memory, to establish searchable verified databases of human rights violations, and provide potential evidence used for legal case building.",
    ],
    callToAction: {
      text: "If you need help to create preservation and verification strategies and infrastructure",
      button: "Get in touch",
      href: "contact-us",
    },
  },
];

export default works;
