import React, { useContext } from "react";
import { graphql } from "gatsby";
import LocalizedLink from "components/LocalizedLink";
import Layout from "components/layout";
import SEO from "components/seo";
import { PageNav, NavItem } from "../style/theme";
import { AiFillCaretUp } from "react-icons/ai";
import { jsx } from "@emotion/core";
import { mq } from "../utils/helper";
import works from "../../data/our-work";
import { ThemeContext } from "context/ThemeContext";

function OurWork() {
  const { colors } = useContext(ThemeContext);
  return (
    <div
      css={{
        position: "relative",
        overflow: "hidden"
      }}
    >
      <SEO title="Our work" />
      <div
        css={{
          display: "flex",
          padding: "0 0.1rem 0.1rem 0.1rem",
          color: colors.light,
          position: "fixed",
          fontSize: "30px",
          border: `2px solid ${colors.primary}`,
          top: "90%",
          right: "10%",
          zIndex: 4,
          cursor: "pointer"
        }}
        onClick={() =>
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
        }
      >
        <AiFillCaretUp />
      </div>
      <img
        src="/assets/our-work-background.gif"
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "auto",
          zIndex: 0,
          opacity: 0.25
        }}
      />

      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            justifyContent: "space-between",
            display: "flex",
            position: "relative",
            zIndex: 2,
            [mq[1]]: {
              display: "block",
              marginLeft: "2rem"
            }
          }}
        >
          <h2
            css={{
              color: colors.primary,
              margin: 0,
              fontSize: "48px",
              display: "block"
            }}
          >
            Our Work
          </h2>
          <PageNav>
            {works.map(node => (
              <NavItem href={`#${node.id}`}>
                <span
                  css={{
                    display: "none",
                    [mq[1]]: {
                      display: "inline"
                    }
                  }}
                >
                  +
                </span>{" "}
                {node.title}
              </NavItem>
            ))}
          </PageNav>
        </div>
        {works.map(node => (
          <Work item={node} key={node.id} />
        ))}
      </Layout>
    </div>
  );
}

function Work({ item }) {
  const { colors } = useContext(ThemeContext);
  return (
    <>
      <div
        id={item.id}
        css={{
          display: "flex",
          maxWidth: "1200px",
          margin: "100px auto",
          justifyContent: "space-between",
          position: "relative",
          zIndex: 2,
          [mq[1]]: {
            marginLeft: "2rem",
            marginRight: "2rem",
            display: "block"
          }
        }}
      >
        <div>
          <h2 css={{ color: colors.light, width: "203px", marginTop: 0 }}>
            {item.title}
          </h2>
          <img src={item.img} />
        </div>

        <div>
          {item.text.map(p => (
            <div
              css={{
                color: colors.light,
                fontSize: "25px",
                width: "830px",
                marginRight: 0,
                marginBottom: "1.5rem",
                "& a": {
                  color: colors.light,
                  textDecoration: `underline solid ${colors.primary}`
                },
                "& a:hover": {
                  color: colors.primary,
                  textDecoration: `underline solid ${colors.primary}`
                },
                [mq[1]]: {
                  width: "100%"
                }
              }}
              dangerouslySetInnerHTML={{ __html: p }}
            />
          ))}

          <div
            css={{
              border: `1px solid ${colors.primary}`,
              display: "flex",
              alignItems: "center",
              width: "750px",
              padding: "2rem",
              justifyContent: "space-between",
              color: colors.light,
              [mq[1]]: {
                width: "100%",
                display: "block"
              }
            }}
          >
            <p
              css={{
                fontSize: "20px",
                width: "420px",
                margin: 0,
                [mq[1]]: {
                  width: "100%",
                  marginBottom: "2rem"
                }
              }}
            >
              {item.callToAction.text}
            </p>
            <LocalizedLink
              css={{
                display: "flex",
                alignItems: "center",
                padding: "0 1rem",
                paddingTop: "0.2rem",
                height: "40px",
                color: colors.light,
                fontSize: "20px",
                border: `3px solid ${colors.primary}`,
                ": hover": {
                  color: colors.primary
                },
                [mq[1]]: {
                  display: "inline",
                  padding: "0.3rem 1rem 0.1rem 1rem"
                }
              }}
              to={item.callToAction.href}
            >
              {item.callToAction.button}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurWork;
